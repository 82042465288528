/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import RecordRTC from 'recordrtc';
import { selectRecordingState, useHMSActions, useHMSStore } from '@100mslive/react-sdk';
import Modal from '../components/commonComponent/Modal/Modal';
import DesktopProgressPage from '../components/DesktopProgressPage';
import FullPageProgress from '../components/FullPageProgress';
import { NetworkConnectionLost } from '../components/Pages/components/NetworkConnectionLost';
import TabSwitch from '../components/Pages/components/portal/TabSwitch';
import VideoStream from '../components/Pages/components/portal/VideoStream';
import Viewer from '../components/Preview/PreviewJoin/components/Viewer';
import { ToastManager } from '../components/Toast/ToastManager';
import {
    candidateCallEnd,
    fetchScreenShotImage,
    getQuestion,
    lookingForward,
    movementRequest,
    notificationError,
    resetCodeSubmissionOutput,
    submitCodingQuestion,
    submitMcq,
    submitTranscribe,
    switchTab,
} from '../redux/action';
import { getCodeSubmissionOutput } from '../redux/reducer/interviewReducer';
import { ConferenceMainView } from './mainView';
import MobileMainView from './mobileMainView';
import { getDecodedOutput } from '../common/utils';
import * as SpeechSDK from 'microsoft-cognitiveservices-speech-sdk';
import {
    generateQuestionSuffix,
    generateUrl,
    getBrowserInfoMessage,
    getLatencyText,
    isMobileOrTabletDevice,
    isValidDataURI,
    noAnswer,
    removeQuotes,
} from '../utils/helper';
import { DEFAULT_FORM, DEFAULT_MCQ_FORM, QUESTION_TYPES, RTMP_RECORD_DEFAULT_RESOLUTION } from '../common/constants';
import { defaultVoice, emptyTranscriptText, irrelevantText } from '../utils/constants';

export const MainViewConnector = ({
    form,
    socket,
    isPlaying,
    setIsPlaying,
    prepareTime,
    setPrepareTime,
    answerTime,
    setAnswerTime,
    displayInput,
    setDisplayInput,
    popupInputs,
    setPopupInputs,
    questionNumber,
    toggleControls,
    candidatePeer,
    setIsSubmitModalOpen,
    isSubmitModalOpen,
    lastQuestionSubmitTimeStampAptitude,
    setScreenShareVidRef,
}) => {
    const dispatch = useDispatch();
    const hmsActions = useHMSActions();
    const codeOutput = useSelector(getCodeSubmissionOutput);
    const recordingState = useHMSStore(selectRecordingState);
    const websocketRef = useRef(null);
    const synthesizerRef = useRef(null);
    const recognizerRef = useRef(null);
    const speechConfigRef = useRef(null);
    const accumulatedTextRef = useRef('');
    const speakTimeoutRef = useRef(null);
    const evaluateNextQuestionRef = useRef(false);
    const { roomId, role } = useParams();
    const [transcript, setTranscript] = useState('');
    const [isConnected, setIsConnected] = useState(false);
    const [timeoutId, setTimeoutId] = useState(null);
    const [isNetworkError, setIsNetworkError] = useState(false);
    const [isUpdated, setIsUpdated] = useState(false);
    const [recording, setRecording] = useState(false);
    const [mediaStream, setMediaStream] = useState(null);
    const [audioPlayer, setAudioPlayer] = useState();
    const [interviewStarted, setInterviewStarted] = useState(false);
    const [onlineStatus, setOnlineStatus] = useState(true);
    const [recorder, setRecorder] = useState(null);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [timer, setTimer] = useState(5);
    const [resolution, setResolution] = useState(RTMP_RECORD_DEFAULT_RESOLUTION);
    const [answerDuration, setAnswerDuration] = useState(0);
    const [triggered, setTriggered] = useState(false);
    const [recordingTriggered, setRecordingTriggered] = useState(false);
    const [waitingPrepTime, setWaitingPrepTime] = useState(false);
    const [waitingAnswerTime, setWaitingAnswerTime] = useState(false);
    const [isQuestionTypeCoding, setIsQuestionTypeCoding] = useState(false);
    const [isQuestionTypeMcq, setIsQuestionTypeMcq] = useState(false);
    const [aptitude, setAptitude] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [countdown, setCountdown] = useState(30);
    const transcriptRef = useRef('');
    const [conversationData, setConversationData] = useState({
        mainQuestion: '',
        previousRequest: [],
        response: '',
        conversationStatus: false,
        questionNumber: 0,
    });
    const [questionInfo, setQuestionInfo] = useState({
        question: '',
        questionAnswer: '',
        prepareTime: 0,
        timeToAnswer: 0,
        questionNumber: 1,
        actualQuestion: '',
        domain: '',
        questionType: '',
        audioUrl: '',
    });
    let currentAudio = null;
    const [eyeMovement, setEyeMovement] = useState({
        time: 0,
        uri: '',
        date: '',
    });
    const [faceCount, setFaceCount] = useState({
        time: 0,
        uri: '',
        date: '',
    });
    const [deviceCount, setDeviceCount] = useState({
        time: 0,
        uri: '',
        date: '',
    });
    const [questionInput, setQuestionInput] = useState({
        interviewId: form?.interviewId,
        questionId: form?.questionId,
        questionNumber: questionNumber,
    });
    const [showStuckPopup, setShowStuckPopup] = useState(false);
    const [codeEditorForm, setCodeEditorForm] = useState(DEFAULT_FORM);
    const [hasAnythingChangedInCodeEditor, setHasAnythingChangedInCodeEditor] = useState(false);
    const [mcqForm, setMcqForm] = useState(DEFAULT_MCQ_FORM);
    const [questionNumberTotal, setQuestionNumberTotal] = useState(0);
    const [selectedOption, setSelectedOption] = useState(null);
    const [stopForProceed, setStopForProceed] = useState(false);
    const questionInfoRef = useRef(questionInfo);
    const questionInputRef = useRef(questionInput);
    const conversationDataRef = useRef(conversationData);
    const isSpeakingRef = useRef(false);
    const isQuestionReceived = useRef(false);
    const isResponsePauseCheck = useRef(false);
    const isWebSocketResponseCheck = useRef(true);
    const isConnectedRef = useRef(isConnected);
    const confirmLeave = useRef(false);
    const mcqInterruptedTime = useRef(0);
    const logSuccess = (message) => console.log(`✅ Success: ${message}`);
    const logError = (message, error) => console.error(`❌ Error: ${message}`, error);

    const updateSocket = (type, value) => {
        try {
            socket?.emit('codeWritten', { type, value }, roomId);
            logSuccess(`Socket updated successfully with type: ${type}`);
        } catch (error) {
            logError('Failed to update socket', error);
        }
    };

    useEffect(() => {
        if (socket && roomId) {
            const intervalId = setInterval(() => {
                const timestamp = new Date().toISOString();
                socket.emit('interview-active', { roomId, timestamp });
            }, 30000);
            return () => clearInterval(intervalId);
        }
    }, [socket, roomId]);

    useEffect(() => {
        questionInfoRef.current = questionInfo;
        logSuccess('Updated questionInfoRef successfully');
    }, [questionInfo]);

    useEffect(() => {
        questionInputRef.current = questionInput;
        logSuccess('Updated questionInputRef successfully');
    }, [questionInput]);

    useEffect(() => {
        conversationDataRef.current = conversationData;
        logSuccess('Updated conversationDataRef successfully');
    }, [conversationData]);

    useEffect(() => {
        isConnectedRef.current = isConnected;
        logSuccess('Updated isConnectedRef successfully');
    }, [isConnected]);

    useEffect(() => {
        if (isPlaying && isQuestionTypeMcq) {
            setStopForProceed(true);
            logSuccess('Set stopForProceed to true for MCQ question type');
        }
    }, [isPlaying, isQuestionTypeMcq]);

    useEffect(() => {
        const countdown = setInterval(() => {
            if (timer > 0) {
                setTimer(timer - 1);
                logSuccess(`Timer decremented to ${timer - 1}`);
            } else {
                clearInterval(countdown);
                logSuccess('Timer countdown cleared');
            }
        }, 1000);
        return () => {
            clearInterval(countdown);
            logSuccess('Cleanup interval on component unmount');
        };
    }, [timer]);

    useEffect(() => {
        setQuestionInput((prevInput) => ({
            ...prevInput,
            questionNumber: questionNumber + 1,
        }));
        logSuccess(`Question input updated to questionNumber ${questionNumber + 1}`);
    }, [questionNumber]);

    useEffect(() => {
        const handleRecordingSubmission = async () => {
            if (!recordingTriggered && !recordingState?.browser?.running) {
                await handleRecordingOn();
                setRecordingTriggered(true);
                logSuccess('Recording triggered and started successfully');
                if (form?.conversationAI) {
                    initializeSpeechServices();
                    logSuccess('Speech services initialized');
                    initializeWebSocket();
                    logSuccess('WebSocket initialized');
                }
            }
        };
        handleRecordingSubmission();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const handleSubmission = async () => {
            if (!triggered && recordingState?.browser?.running && questionInput?.questionNumber) {
                if (lastQuestionSubmitTimeStampAptitude) {
                    await handleSubmit('Mobile', null, lastQuestionSubmitTimeStampAptitude);
                    logSuccess('Submission handled with last question timestamp for Mobile');
                } else {
                    await handleSubmit('Mobile');
                    logSuccess('Submission handled for Mobile');
                }
                setTriggered(true);
                logSuccess('Triggered state updated to true');
            }
        };
        handleSubmission();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recordingState?.browser?.running, questionInput?.questionNumber]);

    const handleEndCall = async (isNetworkDisconnect = false, endCallStatus) => {
        try {
            logSuccess('Starting to end the call process');
            if (recording && !isQuestionTypeCoding) {
                logSuccess('Stopping recording');
                await stopRecording();
            }
            if (isQuestionTypeCoding) {
                logSuccess('Saving coding question data');
                saveCodingQuestion({ data: codeOutput });
            }
            confirmLeave.current = true;
            // Rest of your code in handleEndCall
            if (form?.conversationAI && isConnectedRef.current) {
                logSuccess('Closing WebSocket connection');
                if (speakTimeoutRef.current) clearTimeout(speakTimeoutRef.current);
                websocketRef.current?.close();
            }

            logSuccess('Stopping camera streams');
            stopMediaStreams();

            if (recordingState?.browser?.running) {
                logSuccess('Stopping RTMP/browser recording');
                handleRecordingOff();
            }
            const doFinally = () => {
                logSuccess('Leaving the room');
                hmsActions.leave();
                ToastManager.clearAllToast();
                window.location.href = `/leave/${role}/${roomId}`;
            };
            if (onlineStatus) {
                if (isNetworkDisconnect) {
                    // logSuccess('Posting interview status: Network Disconnected');
                    // dispatch(postInterviewStatus({ roomId, status: 'Network Disconnected', doFinally }));
                    doFinally();
                } else {
                    logSuccess(`Posting interview status: ${endCallStatus}`);
                    dispatch(
                        candidateCallEnd({
                            roomId,
                            endCallStatus,
                            doFinally,
                        })
                    );
                }
            }
        } catch (error) {
            console.error('Error in handleEndCall:', error);
            handleRecordingError(error);
        }
    };

    const stopMediaStreams = async () => {
        try {
            const devices = await navigator.mediaDevices.enumerateDevices();
            const cameras = devices.filter((device) => device.kind === 'videoinput');
            for (const camera of cameras) {
                const stream = await navigator.mediaDevices.getUserMedia({ video: { deviceId: camera.deviceId } });
                stream.getTracks().forEach((track) => track.stop());
            }
            logSuccess('All camera streams stopped successfully');
        } catch (error) {
            logError('Failed to stop media streams', error);
        }
    };

    const handleBrowserEndCall = () => {
        try {
            logSuccess('Attempting to handle browser end call.');
            const doFinally = () => {
                logSuccess('Clean up actions successfully executed in handleBrowserEndCall.');
            };
            dispatch(
                candidateCallEnd({
                    roomId: roomId,
                    endCallStatus: 'Browser Closed',
                    doFinally,
                })
            );
            logSuccess('Successfully dispatched candidateCallEnd with status: "Browser Closed".');
            if (recordingState?.browser?.running) {
                hmsActions.stopRTMPAndRecording();
                logSuccess('Successfully stopped RTMP and browser recording.');
            } else {
                logError('No active browser recording to stop.');
            }
        } catch (error) {
            logError(`Error occurred in handleBrowserEndCall: ${error}`);
        }
    };

    const initializeSpeechServices = () => {
        try {
            console.log('Initializing speech services.');
            const subscriptionKey = process.env.REACT_APP_MICROSOFT_KEY;
            const region = process.env.REACT_APP_MICROSOFT_REGION;

            if (!subscriptionKey || !region) {
                logError('Microsoft subscription key or region is missing from environment variables.');
                return;
            }

            logSuccess('Successfully retrieved Microsoft subscription key and region.');

            const speechConfig = SpeechSDK.SpeechConfig.fromSubscription(subscriptionKey, region);
            speechConfig.speechRecognitionLanguage = 'en-IN';
            speechConfig.speechSynthesisVoiceName = form?.voiceName ?? defaultVoice;
            speechConfigRef.current = speechConfig;

            recognizerRef.current = new SpeechSDK.SpeechRecognizer(speechConfig);
            synthesizerRef.current = new SpeechSDK.SpeechSynthesizer(speechConfig, null);

            logSuccess('Speech services successfully initialized.');

            let lastRecognizedText = '';

            // Handle recognizing events
            recognizerRef.current.recognizing = (_, e) => {
                try {
                    const recognizedText = e.result.text?.trim(); // Get the recognized text
                    if (!recognizedText || recognizedText === lastRecognizedText) return; // Skip if undefined or duplicate

                    // Add only new text to the transcript
                    const newText = recognizedText.replace(lastRecognizedText, '').trim();
                    if (newText) {
                        transcriptRef.current = `${transcriptRef.current} ${newText}`.trim(); // Update ref
                        setTranscript(transcriptRef.current); // Update state
                        lastRecognizedText = recognizedText; // Update last recognized text
                    }
                    logSuccess(`Updated transcript successfully: ${transcriptRef.current}`);
                } catch (error) {
                    logError(`Error in recognizer "recognizing" event: ${error}`);
                }
            };

            // Handle canceled events
            recognizerRef.current.canceled = (_, e) => {
                logError(`Speech recognition canceled: ${e}`);
            };
            logSuccess('Speech recognition and synthesis event handlers set up successfully.');
        } catch (error) {
            logError(`Error initializing speech services: ${error}`);
        }
    };

    const startRecording = async () => {
        try {
            logSuccess('Attempting to start recording');
            if (form?.conversationAI && isConnectedRef.current) {
                if (recognizerRef.current) {
                    setTranscript('');
                    transcriptRef.current = '';
                    logSuccess('Starting continuous speech recognition');
                    await recognizerRef.current.startContinuousRecognitionAsync();
                }
            }
            const stream = await navigator?.mediaDevices?.getUserMedia({ audio: true });
            if (stream) {
                setMediaStream(stream);
                const mediaRecorder = new RecordRTC(stream, {
                    type: 'audio',
                    mimeType: 'audio/wav', // Adjust the MIME type based on your needs
                });
                mediaRecorder.startRecording();
                setRecorder(mediaRecorder);
                setRecording(true);
                logSuccess(`Recording started successfully for question number : ${questionInfo?.questionNumber}`);
            } else {
                logError('Failed to obtain audio stream');
            }
        } catch (error) {
            logError('Error while starting recording', error);
        }
    };

    const stopRecording = async () => {
        if (recorder) {
            try {
                recorder.stopRecording(async () => {
                    const blob = recorder.getBlob();
                    logSuccess(`Recording stopped. Blob size: ${blob.size} bytes`);
                    const formData = new FormData();
                    formData.append('video', blob, 'recorded-audio.wav');
                    formData.append('interviewId', form?.interviewId);
                    formData.append('question', questionInfo?.actualQuestion);
                    formData.append('questionType', questionInfo?.questionType);
                    formData.append('positionTitle', form?.positionTitle);
                    formData.append('answerTimeTaken', questionInfo?.timeToAnswer);
                    formData.append('domain', questionInfo?.domain);
                    formData.append(
                        'timeTaken',
                        questionInfo?.timeToAnswer > answerDuration ? answerDuration : questionInfo?.timeToAnswer
                    );
                    console.log(conversationDataRef.current, 'conversationDataRef.current');
                    console.log(questionInfo?.actualQuestion, 'questionInfo?.actualQuestion');
                    const transcriptFinal = transcript.trim();
                    const previousRequest = JSON.stringify({
                        question: questionInfo?.actualQuestion,
                        transcript: transcriptFinal,
                        conversational_history: conversationDataRef.current?.previousRequest,
                    });
                    if (
                        conversationDataRef.current?.conversationStatus &&
                        conversationDataRef.current?.mainQuestion !== questionInfo?.actualQuestion
                    ) {
                        formData.append('conversationAIStatus', true);
                        formData.append('mainQuestion', conversationDataRef.current?.mainQuestion || '');
                        formData.append('previousRequest', previousRequest);
                    }
                    formData.forEach((value, key) => {
                        console.log(`${key}:`, value);
                    });
                    dispatch(submitTranscribe(formData));
                    recorder.clearRecordedData();
                    if (mediaStream) {
                        const tracks = mediaStream.getTracks();
                        tracks.forEach((track) => track.stop());
                    }
                    setRecording(false);
                    logSuccess(
                        `Recording data cleared and tracks stopped for question number :  ${questionInfo?.questionNumber}`
                    );
                });
            } catch (error) {
                logError('Failed to stop recording', error);
                handleRecordingError(error);
            }
        } else {
            logError('No active recorder found');
        }
    };

    useEffect(() => {
        if (timeoutId) {
            clearTimeout(timeoutId);
            logSuccess('Cleared existing timeout.');
        }
        const newTimeoutId = setTimeout(async () => {
            if (!onlineStatus) {
                try {
                    logSuccess('Checking online status for timeout action.');
                    if (recording && !isQuestionTypeCoding) {
                        logSuccess('Stopping recording due to offline status.');
                        await stopRecording();
                    }
                    socket?.close();
                    logSuccess('Socket closed due to offline status.');

                    if (isPlaying) {
                        if (audioPlayer) {
                            logSuccess('Pausing audio player.');
                            audioPlayer.pause();
                        }
                        setIsPlaying(false);
                        setWaitingPrepTime(false);
                        setWaitingAnswerTime(false);
                        updateSocket('isPlaying', false);
                        logSuccess('Updated state and socket for offline status.');
                    }
                    setIsNetworkError(true);
                    logSuccess('Network error state set to true.');
                } catch (error) {
                    logError('Error during timeout handling', error);
                }
            }
        }, 10000);
        setTimeoutId(newTimeoutId);
        logSuccess('Set new timeout for 10 seconds.');
    }, [onlineStatus]);

    const handleRecordingOn = async () => {
        try {
            await hmsActions.startRTMPOrRecording({
                meetingURL: `${generateUrl('meeting')}/preview/beam/${roomId}?skip_preview=true`,
                resolution: setResolution(resolution),
                record: true,
            });
            logSuccess('Started RTMP or recording successfully.');
        } catch (error) {
            logError('Error starting RTMP or recording', error);
        }
    };

    const handleRecordingOff = async () => {
        try {
            await hmsActions.stopRTMPAndRecording();
            logSuccess('Stopped RTMP and recording successfully.');
        } catch (error) {
            logError('Error stopping RTMP and recording', error);
        }
    };

    const handleRecordingError = (error) => {
        try {
            const newMessage = `${getBrowserInfoMessage()} Message: ${error.message} Room Id: ${roomId}`;
            dispatch(notificationError({ message: newMessage }));
            logSuccess('Dispatched notification error successfully.');
        } catch (err) {
            logError('Failed to handle recording error', err);
        }
    };

    const saveMovement = (currentCount, msg, dataURI, currentDate) => {
        if (isValidDataURI(dataURI)) {
            logSuccess('Valid data URI, proceeding to dispatch lookingForward action.');
            dispatch(
                lookingForward({
                    image: dataURI,
                    callback: (isLookingForward) => {
                        if (!isLookingForward) {
                            logSuccess('Fetching and saving image for movement request.');
                            fetchAndSaveImage(dataURI, currentCount, msg, currentDate);
                        }
                    },
                })
            );
        } else {
            logError('Invalid data URI, movement save aborted.', null);
        }
    };

    const fetchAndSaveImage = (dataURI, currentCount, msg, currentDate) => {
        if (onlineStatus) {
            logSuccess('Fetching screenshot image as online status is true.');
            dispatch(
                fetchScreenShotImage({
                    image: dataURI,
                    interviewId: roomId,
                    type: 10,
                    callback: (image) => {
                        if (isValidDataURI(image)) {
                            logSuccess('Fetched valid image, making movement request.');
                            makeMovementRequest(image, currentCount, msg, currentDate);
                        } else {
                            logError('Invalid image URI fetched, movement request aborted.', null);
                        }
                    },
                })
            );
        } else {
            logError('Offline status, fetch and save image aborted.', null);
        }
    };

    const makeMovementRequest = (image, currentCount, msg, currentDate) => {
        if (onlineStatus) {
            logSuccess('Dispatching movement request as online status is true.');
            dispatch(
                movementRequest({
                    roomId: roomId,
                    interviewId: form?.interviewId,
                    message: msg,
                    image: image,
                    dateAndTime: currentDate,
                    duration: currentCount,
                })
            );
        } else {
            logError('Offline status, movement request aborted.', null);
        }
    };

    const offlineCallback = () => {
        logSuccess('Offline event detected.');
        setOnlineStatus(false);
        socket?.disconnect();
        logSuccess('Socket disconnected due to offline event.');
    };

    const onlineCallback = () => {
        logSuccess('Online event detected.');
        setOnlineStatus(true);
        socket?.connect();
        logSuccess('Socket connected due to online event.');
    };

    useEffect(() => {
        window.addEventListener('offline', offlineCallback);
        window.addEventListener('online', onlineCallback);
        logSuccess('Added event listeners for online and offline events.');

        return () => {
            window.removeEventListener('offline', offlineCallback);
            window.removeEventListener('online', onlineCallback);
            logSuccess('Removed event listeners for online and offline events.');
        };
    }, []);

    useEffect(() => {
        const handleBeforeUnload = (e) => {
            if (!confirmLeave.current) {
                e.preventDefault();
                e.returnValue = '';
                logSuccess('Before unload triggered, preventing default action.');
                return 'Are you sure you want to leave? Your task may not be saved.';
            }
        };
        const handleUnload = () => {
            if (!confirmLeave.current) {
                logSuccess('Unload event detected, handling browser end call.');
                handleBrowserEndCall();
            }
        };
        window.addEventListener('beforeunload', handleBeforeUnload);
        window.addEventListener('unload', handleUnload);
        window.addEventListener('popstate', handleUnload);
        logSuccess('Added event listeners for unload and popstate.');
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            window.removeEventListener('unload', handleUnload);
            window.removeEventListener('popstate', handleUnload);
            logSuccess('Removed event listeners for unload and popstate.');
        };
    }, [confirmLeave]);

    const timerEnd = () => {
        setPrepareTime(0);
        updateSocket('prepareTime', 0);
        setAnswerTime(0);
        updateSocket('answerTime', 0);
        logSuccess('Timer ended and reset state successfully.');
    };

    const saveMcqQuestion = () => {
        try {
            logSuccess('Attempting to save MCQ question');
            dispatch(
                submitMcq({
                    interviewId: form?.interviewId,
                    question: questionInfo?.actualQuestion,
                    questionSubmitTimestamp: answerDuration,
                    questionType: questionInfo?.questionType,
                    difficultyLevel: mcqForm?.difficultyLevel,
                    answer: mcqForm[`option_${selectedOption}`],
                    questionNumber: questionNumberTotal,
                    positionTitle: '',
                    answerTimeTaken: '',
                })
            );
            logSuccess('MCQ question saved successfully');
        } catch (error) {
            logError('Failed to save MCQ question', error);
        }
    };

    const saveCodingQuestion = ({ data }) => {
        try {
            logSuccess('Attempting to save coding question');
            const result = data ?? codeOutput;
            dispatch(
                submitCodingQuestion({
                    data: {
                        memory: result?.memory || 0,
                        runTime: result?.time || 0,
                        finalResult: getDecodedOutput(result) || '',
                        programmingLanguage: result?.language?.name || codeEditorForm?.programmingLanguage,
                        interviewId: form?.interviewId,
                        question: questionInfo?.actualQuestion,
                        timeTaken:
                            questionInfo?.timeToAnswer > answerDuration ? answerDuration : questionInfo?.timeToAnswer,
                        questionType: questionInfo?.questionType,
                        languageCode: codeEditorForm?.language || '',
                        code: codeEditorForm?.codeEditor || '',
                        prebuilt: codeEditorForm?.prebuilt || '',
                        questionLevel: codeEditorForm?.difficultyLevel,
                        questionId: codeEditorForm?.questionId,
                    },
                })
            );
            dispatch(resetCodeSubmissionOutput());
            if (hasAnythingChangedInCodeEditor) {
                setHasAnythingChangedInCodeEditor(false);
            }
            logSuccess('Coding question saved successfully');
        } catch (error) {
            logError('Failed to save coding question', error);
        }
    };

    const handleConSubmit = async (conStatus, mode) => {
        logSuccess('handleConSubmit called');
        const transcriptFinal = transcript.trim();
        isResponsePauseCheck.current = true;
        try {
            if (recording && !isQuestionTypeCoding) {
                logSuccess('Stopping recording before conversation submission');
                await stopRecording();
            }
            if (recognizerRef.current) {
                logSuccess('Stopping continuous recognition');
                await recognizerRef.current.stopContinuousRecognitionAsync();
            }
            if (!isValidSubmission(conStatus, transcriptFinal)) {
                logSuccess('Submission is invalid');
                if (mode === 'Desktop' && !transcriptFinal) {
                    logSuccess('Handling invalid submission for Desktop mode');
                    handleInvalidSubmission(mode, transcriptFinal);
                } else {
                    logSuccess('Handling invalid submission for Mobile mode');
                    await handleSubmit('Mobile');
                }
                return;
            } else if (websocketRef.current?.readyState === WebSocket.OPEN) {
                logSuccess('Sending WebSocket request');
                await sendWebSocketRequest(transcriptFinal, mode);
            }
        } catch (error) {
            logError('Error during handleConSubmit', error);
            if (!isValidSubmission(conStatus, transcriptFinal)) {
                logError('Invalid submission encountered. Redirecting to handleSubmit for Mobile mode');
                await handleSubmit('Mobile');
            }
        }
    };

    const isValidSubmission = (conStatus, transcriptFinal) => {
        const isValid = form?.conversationAI && isConnectedRef.current && conStatus && transcriptFinal;
        logSuccess(`Validation result for submission: ${isValid}`);
        return isValid;
    };

    const handleInvalidSubmission = () => {
        const value = {
            message: emptyTranscriptText,
            displayText: emptyTranscriptText,
            audio: '',
            codeBlock: '',
            audioPlay: true,
            callHandleSubmit: true,
            clearPlaying: false,
            audioData: null,
        };
        setDisplayInput(value);
        updateSocket('displayInput', value);
        logSuccess('Invalid submission processed successfully');
    };

    const sendWebSocketRequest = async (transcriptFinal, mode) => {
        try {
            logSuccess('Preparing WebSocket request');
            isWebSocketResponseCheck.current = true;
            const previousRequest = JSON.stringify({
                question_type: questionInfo?.questionType === 'global' ? 'general' : 'specific',
                question: questionInfo?.actualQuestion,
                transcript: transcriptFinal,
                conversational_history: conversationDataRef.current?.previousRequest,
            });
            setConversationData((prev) => ({
                ...prev,
                conversational_history: [
                    ...(prev.conversational_history ?? []),
                    {
                        question: questionInfo?.actualQuestion,
                        transcript: transcriptFinal,
                    },
                ],
            }));
            logSuccess(`WebSocket request payload: ${previousRequest}`);
            websocketRef.current.send(previousRequest);

            if (mode === 'Desktop' && !isSpeakingRef.current) {
                logSuccess('Handling transcript response for Desktop mode');
                handleTranscriptResponse(transcriptFinal);
            } else {
                isResponsePauseCheck.current = false;
            }

            const messageReceived = await waitForWebSocketResponse();
            if (!messageReceived) {
                logError('No WebSocket message received within 5 seconds');
                await handleSubmit('Mobile');
            } else {
                logSuccess('WebSocket message received successfully');
            }
        } catch (error) {
            logError('Error during WebSocket request', error);
        }
    };

    const handleTranscriptResponse = (transcriptFinal) => {
        const transcriptText =
            transcriptFinal.length > 9 ? getLatencyText(form?.candidateName) : noAnswer(form?.candidateName);
        logSuccess(`Transcript generated successfully: ${transcriptText}`);
        const value = {
            message: transcriptText,
            displayText: transcriptText,
            audio: '',
            codeBlock: '',
            audioPlay: true,
            callHandleSubmit: false,
            clearPlaying: false,
            audioData: null,
        };
        setDisplayInput(value);
        updateSocket('displayInput', value);
    };

    const waitForWebSocketResponse = () => {
        return new Promise((resolve) => {
            let messageTimeout = null;
            const messageListener = (event) => {
                const message = JSON.parse(event.data);
                logSuccess(`WebSocket message received: ${JSON.stringify(message)}`);
                if (message.type === 'stream' || message.type === 'end_stream') {
                    clearTimeout(messageTimeout);
                    websocketRef.current.removeEventListener('message', messageListener);
                    resolve(true);
                }
            };
            websocketRef.current.addEventListener('message', messageListener);
            messageTimeout = setTimeout(() => {
                isWebSocketResponseCheck.current = false;
                logError('WebSocket response timeout: No message received within 5 seconds');
                websocketRef.current.removeEventListener('message', messageListener);
                resolve(false);
            }, 5000); // 5-second timeout
        });
    };

    const handleConversationSubmit = async (question) => {
        try {
            const questionText = generateQuestionSuffix(question, 10);
            logSuccess('Handling conversation submit');
            setWaitingPrepTime(true);
            if (onlineStatus) {
                setQuestionInfo((prevState) => ({
                    ...prevState,
                    question: questionText,
                    questionAnswer: '',
                    prepareTime: 10,
                    timeToAnswer: 120,
                    actualQuestion: question,
                }));
                setIsQuestionTypeCoding(false);
                setPrepareTime(10);
                updateSocket('prepareTime', 10);
                setAnswerTime(120);
                updateSocket('answerTime', 120);
                setConversationData((prevState) => ({
                    ...prevState,
                    questionNumber: prevState.questionNumber + 1,
                }));
                const value = {
                    message: questionText,
                    displayText: `Question-${parseInt(
                        questionInfoRef.current?.questionNumber
                    )}.${parseInt(conversationDataRef.current?.questionNumber + 1)}: ${question}`,
                    audio: '',
                    codeBlock: '',
                    audioPlay: true,
                    callHandleSubmit: false,
                    clearPlaying: true,
                    audioData: null,
                };
                setDisplayInput(value);
                updateSocket('displayInput', value);
                setIsUpdated(true);
                setAnswerDuration(0);
                logSuccess('Conversation submitted successfully');
            } else {
                logError('Failed to submit conversation: offline status');
            }
        } catch (error) {
            logError('Error in handleConversationSubmit', error);
            handleRecordingError(error);
        } finally {
            evaluateNextQuestionRef.current = false;
        }
    };

    const handleSubmit = async (mode, mcqNextQuestion, lastQuestionSubmitTimeStampAptitude) => {
        try {
            logSuccess('Handle submit process started');
            setWaitingPrepTime(true);
            if (recording && !isQuestionTypeCoding) {
                logSuccess('Stopping recording');
                await stopRecording();
            }
            if (isQuestionTypeCoding) {
                logSuccess('Saving coding question data');
                saveCodingQuestion({ data: codeOutput });
            }
            let questionData = questionInputRef.current;
            if (mcqNextQuestion) {
                questionData = {
                    ...questionData,
                    questionNumber: mcqNextQuestion,
                };
                setQuestionInput(questionData);
            }
            if (onlineStatus) {
                dispatch(
                    getQuestion({
                        mode: mode,
                        data: questionData,
                        resumedQuestion: !triggered && questionNumber > 0,
                        callback: (data) => {
                            logSuccess(`Question data fetched successfully: ${JSON.stringify(data)}`);
                            setQuestionInfo((prevState) => ({
                                ...prevState,
                                question: data?.response,
                                questionAnswer: data?.questionAnswer,
                                prepareTime: data?.timeToPrepare,
                                timeToAnswer: data?.timeToAnswer,
                                questionNumber: questionData?.questionNumber || 1,
                                actualQuestion: data?.question,
                                domain: data?.domain,
                                questionType: data?.questionType,
                                audioUrl: data?.audioUrl ?? '',
                            }));
                            if (
                                data?.questionType === QUESTION_TYPES.MCQ.LOGICAL ||
                                data?.questionType === QUESTION_TYPES.MCQ.NUMBER ||
                                data?.questionType === QUESTION_TYPES.MCQ.QUANTITATIVE ||
                                data?.questionType === QUESTION_TYPES.MCQ.VERBAL
                            ) {
                                logSuccess('Handling MCQ question type');
                                setWaitingPrepTime(false);
                                setSelectedOption(null);
                                const mcqForm = {
                                    ...DEFAULT_MCQ_FORM,
                                    option_1: data?.option_1,
                                    option_2: data?.option_2,
                                    option_3: data?.option_3,
                                    option_4: data?.option_4,
                                    difficultyLevel: data?.difficultyLevel,
                                };
                                setAptitude(data?.aptitude);
                                setMcqForm(mcqForm);
                                updateSocket('mcqForm', mcqForm);
                                setIsQuestionTypeMcq(true);
                            } else {
                                setIsQuestionTypeMcq(false);
                            }
                            const conStatus =
                                isConnectedRef.current &&
                                form?.conversationAI &&
                                ['ai-generated', 'custom', 'global'].includes(data?.questionType);
                            logSuccess(`Conversation status determined: ${conStatus}`);
                            const tmpConData = {
                                mainQuestion: conStatus ? data?.question : '',
                                previousRequest: [],
                                response: '',
                                conversationStatus: conStatus,
                                questionNumber: 0,
                            };
                            setConversationData(tmpConData);
                            if (data?.questionType === QUESTION_TYPES.CODING) {
                                logSuccess('Handling coding question type');
                                const codingForm = {
                                    ...DEFAULT_FORM,
                                    preBuiltFunction: data?.preBuiltFunction,
                                    difficultyLevel: data?.difficultyLevel,
                                    questionId: data?.questionId,
                                    neededLanguage: data?.programmingLanguage,
                                };
                                setCodeEditorForm(codingForm);
                                updateSocket('codeEditorForm', codingForm);
                                setIsQuestionTypeCoding(true);
                            } else {
                                setIsQuestionTypeCoding(false);
                            }
                            const currentQuestionNumber = parseInt(questionData?.questionNumber || 1);
                            setQuestionNumberTotal(currentQuestionNumber + 1);
                            const totalQuestions = parseInt(form?.totalQuestion);
                            let questionStatus = currentQuestionNumber <= totalQuestions;
                            if (questionStatus) {
                                logSuccess('Setting up next question');
                                // If there are more questions, update prepare time, answer time, and question number
                                if (
                                    data?.questionType === QUESTION_TYPES.MCQ.LOGICAL ||
                                    data?.questionType === QUESTION_TYPES.MCQ.NUMBER ||
                                    data?.questionType === QUESTION_TYPES.MCQ.QUANTITATIVE ||
                                    data?.questionType === QUESTION_TYPES.MCQ.VERBAL
                                ) {
                                    if (data?.timeToAnswer > 0) {
                                        setPrepareTime(0);
                                        updateSocket('prepareTime', 0);
                                        if (lastQuestionSubmitTimeStampAptitude)
                                            setAnswerTime(data?.timeToAnswer - lastQuestionSubmitTimeStampAptitude);
                                        else setAnswerTime(data?.timeToAnswer);
                                        updateSocket('answerTime', data?.timeToAnswer);
                                    } else if (mcqInterruptedTime.current) {
                                        console.log(mcqInterruptedTime.current, 'setting interrupt ans time');
                                        setPrepareTime(0);
                                        updateSocket('prepareTime', 0);
                                        setAnswerTime(mcqInterruptedTime.current);
                                        updateSocket('answerTime', mcqInterruptedTime.current);
                                    }
                                } else {
                                    setPrepareTime(data?.timeToPrepare);
                                    updateSocket('prepareTime', data?.timeToPrepare);
                                    setAnswerTime(data?.timeToAnswer);
                                    updateSocket('answerTime', data?.timeToAnswer);
                                }
                                if (!interviewStarted) {
                                    setInterviewStarted(true);
                                }
                                setQuestionInput((prevState) => ({
                                    ...prevState,
                                    candidateResponse: '',
                                    questionNumber: currentQuestionNumber + 1,
                                }));
                            } else {
                                timerEnd();
                            }
                            if (mode === 'Mobile') {
                                const pValue = {
                                    ...popupInputs,
                                    questionNumber: currentQuestionNumber + 1,
                                    totalQuestions: totalQuestions,
                                    questionType: data?.questionType,
                                };
                                setPopupInputs(pValue);
                                updateSocket('popupInputs', pValue);
                            }
                            const value = {
                                message: data?.response,
                                displayText: data?.question
                                    ? `Question-${currentQuestionNumber}: ${data?.question}`
                                    : `Question-${currentQuestionNumber}: ${data?.response}`,
                                audio: data?.audioUrl ?? '',
                                codeBlock: data?.codeBlock || '',
                                audioPlay: false,
                                callHandleSubmit: false,
                                clearPlaying: true,
                                audioData: null,
                            };
                            setDisplayInput(value);
                            updateSocket('displayInput', value);
                            setIsUpdated(true);
                            if (
                                !(
                                    data?.questionType === QUESTION_TYPES.MCQ.LOGICAL ||
                                    data?.questionType === QUESTION_TYPES.MCQ.NUMBER ||
                                    data?.questionType === QUESTION_TYPES.MCQ.QUANTITATIVE ||
                                    data?.questionType === QUESTION_TYPES.MCQ.VERBAL
                                ) ||
                                ((data?.questionType === QUESTION_TYPES.MCQ.LOGICAL ||
                                    data?.questionType === QUESTION_TYPES.MCQ.NUMBER ||
                                    data?.questionType === QUESTION_TYPES.MCQ.QUANTITATIVE ||
                                    data?.questionType === QUESTION_TYPES.MCQ.VERBAL) &&
                                    data?.timeToAnswer > 0)
                            )
                                setAnswerDuration(0);
                        },
                    })
                );
            } else {
                logError('Cannot submit question: offline status');
            }
        } catch (error) {
            logError('Error in handleSubmit', error);
            handleRecordingError(error);
        } finally {
            evaluateNextQuestionRef.current = false;
        }
    };

    const handleAudioEnd = async () => {
        logSuccess('handleAudioEnd called');
        setIsPlaying(false);
        setWaitingPrepTime(false);
        setWaitingAnswerTime(false);
        updateSocket('isPlaying', false);
        logSuccess('Audio end handling completed successfully');
    };

    const handleAudioError = (error) => {
        logSuccess('handleAudioError called');
        setIsPlaying(false);
        setWaitingPrepTime(false);
        setWaitingAnswerTime(false);
        updateSocket('isPlaying', false);
        logError('Audio playback error', error);
        handleRecordingError(error);
    };

    useEffect(() => {
        if (audioPlayer) {
            logSuccess('audioPlayer useEffect triggered');
            const interval = setInterval(async () => {
                try {
                    if (audioPlayer.paused) {
                        isResponsePauseCheck.current = false;
                        logSuccess('Audio player paused');
                        clearInterval(interval);
                        if (displayInput?.clearPlaying) {
                            await handleAudioEnd();
                            logSuccess('handleAudioEnd called from audioPlayer useEffect');
                        }
                        setAudioPlayer(null);
                        if (displayInput?.callHandleSubmit) {
                            await handleSubmit('Mobile');
                            logSuccess('handleSubmit called for Mobile in audioPlayer useEffect');
                        }
                    } else if (!isPlaying) {
                        setIsPlaying(true);
                        updateSocket('isPlaying', true);
                        logSuccess('Audio is playing');
                    }
                } catch (error) {
                    logError('Error in audioPlayer interval', error);
                    clearInterval(interval); // Cleanup in case of an error
                }
            }, 100);
            return () => clearInterval(interval); // Cleanup when audioPlayer changes
        }
    }, [audioPlayer, isPlaying]);

    const playAudio = async (url) => {
        logSuccess('playAudio called');
        stopCurrentAudio();
        if (!url) {
            logError('No audio URL provided');
            return;
        }
        const audio = new Audio(url);
        setAudioPlayer(audio);
        currentAudio = audio;
        setupAudioListeners(audio);
        try {
            setIsPlaying(true);
            updateSocket('isPlaying', true);
            await audio.play();
            logSuccess('Audio playback started');
        } catch (error) {
            console.error('Error playing audio:', error);
        }
    };

    const speakText = async (text) => {
        if (!text || !synthesizerRef.current) {
            logError('Invalid input or Speech synthesizer not initialized');
            return;
        }
        try {
            const audioData = await generateAudioData(text);
            if (audioData) {
                const value = {
                    ...displayInput,
                    audioData: audioData,
                };
                setDisplayInput(value);
                updateSocket('displayInput', value);
                const url = createAudioURL(audioData);
                logSuccess(`Audio URL generated: ${url}`);
                playAudio(url);
            }
        } catch (error) {
            logError('Error in speakText', error);
        }
    };

    const generateAudioData = async (text) => {
        const synthesizer = synthesizerRef.current;
        return new Promise((resolve, reject) => {
            synthesizer.speakTextAsync(
                text,
                (result) => {
                    if (result.reason === SpeechSDK.ResultReason.SynthesizingAudioCompleted) {
                        logSuccess('Audio synthesis completed successfully');
                        resolve(result.audioData);
                    } else {
                        const errorDetails = `Speech synthesis canceled: ${result.errorDetails}`;
                        logError(errorDetails);
                        reject(new Error(errorDetails));
                    }
                },
                (error) => {
                    logError('Error during speech synthesis', error);
                    reject(error);
                }
            );
        });
    };

    const stopCurrentAudio = () => {
        if (currentAudio) {
            currentAudio.pause();
            currentAudio.currentTime = 0;
            currentAudio = null;
            logSuccess('Current audio stopped');
        }
    };

    const createAudioURL = (audioData) => {
        const blob = new Blob([audioData], { type: 'audio/wav' });
        const url = URL.createObjectURL(blob);
        logSuccess(`Audio URL created: ${url}`);
        return url;
    };

    const setupAudioListeners = (audio) => {
        audio.addEventListener('error', handleAudioError);
        const stopAudio = () => {
            try {
                if (audio) {
                    audio.pause();
                    audio.currentTime = 0;
                    setIsPlaying(false);
                    updateSocket('isPlaying', false);
                    logSuccess('Audio stopped from event listener');
                }
            } catch (error) {
                logError('Error in stopAudio listener', error);
            }
        };
        window.addEventListener('beforeunload', stopAudio);
        window.addEventListener('popstate', stopAudio);
        logSuccess('Audio listeners set up successfully');
    };

    const skipTimer = async () => {
        if (!isQuestionTypeCoding) {
            await startRecording();
            logSuccess('startRecording function called from skipTimer');
        }
        setPrepareTime(0);
        updateSocket('prepareTime', 0);
        logSuccess('Prepare time reset successfully');
    };

    const processAccumulatedText = async (mode) => {
        isSpeakingRef.current = true;
        try {
            logSuccess('processAccumulatedText called');
            const accumulatedText = removeQuotes(String(accumulatedTextRef.current || '').trim());
            if (accumulatedText?.length > 9) {
                await handleConversationSubmit(accumulatedTextRef.current);
                logSuccess('handleConversationSubmit called in processAccumulatedText');
            } else {
                if (mode === 'Desktop') {
                    const value = {
                        message: irrelevantText,
                        displayText: irrelevantText,
                        audio: '',
                        codeBlock: '',
                        audioPlay: true,
                        callHandleSubmit: true,
                        clearPlaying: false,
                        audioData: null,
                    };
                    setDisplayInput(value);
                    updateSocket('displayInput', value);
                    logSuccess('Irrelevant text displayed for Desktop in processAccumulatedText');
                } else {
                    await handleSubmit('Mobile');
                    logSuccess('handleSubmit called for Mobile in processAccumulatedText');
                }
            }
        } catch (error) {
            logError('Error in processAccumulatedText', error);
            await handleSubmit('Mobile');
            logSuccess('handleSubmit called for Mobile in processAccumulatedText error');
        } finally {
            accumulatedTextRef.current = '';
            isSpeakingRef.current = false;
            isQuestionReceived.current = false;
        }
    };

    const initializeWebSocket = () => {
        const webSocketUrl = `${generateUrl('conversational-ai')}/${roomId}`;
        try {
            const ws = new WebSocket(webSocketUrl);
            ws.onopen = () => {
                setIsConnected(true);
                logSuccess('WebSocket connection established');
            };
            ws.onmessage = (event) => {
                try {
                    const message = JSON.parse(event.data);
                    if (isWebSocketResponseCheck.current) {
                        logSuccess('WebSocket message received');
                        if (speakTimeoutRef.current) clearTimeout(speakTimeoutRef.current);
                        if (message.type === 'stream') {
                            accumulatedTextRef.current += message.content;
                            logSuccess('Stream message content appended');
                        } else if (message.type === 'end_stream') {
                            isQuestionReceived.current = true;
                            logSuccess('End stream message received and processed');
                        }
                    }
                } catch (error) {
                    logError('Error processing WebSocket message', error);
                }
            };
            ws.onclose = () => {
                setIsConnected(false);
                logError('WebSocket closed', 'The connection was closed intentionally or due to an error.');
            };
            ws.onerror = (error) => {
                setIsConnected(false);
                logError('WebSocket encountered an error', error);
            };
            websocketRef.current = ws;
        } catch (error) {
            logError('Error initializing WebSocket', error);
        }
    };

    console.log(
        `isQuestionReceived?.current: ${isQuestionReceived?.current}`,
        `isResponsePauseCheck?.current: ${isResponsePauseCheck?.current}`
    );

    useEffect(() => {
        if (isQuestionReceived?.current && !isResponsePauseCheck?.current) {
            console.log('processAccumulatedText started');
            processAccumulatedText(isMobileOrTabletDevice() ? 'Mobile' : 'Desktop');
        }
    }, [isQuestionReceived?.current, isResponsePauseCheck?.current]);

    useEffect(() => {
        if (onlineStatus && isNetworkError) {
            logError('Network error detected', 'Calling handleEndCall due to network disconnection');
            handleEndCall(true, 'Network Disconnected');
        }
    }, [onlineStatus, isNetworkError]);

    useEffect(() => {
        if (isQuestionTypeCoding) {
            logSuccess('Resetting position for coding question');
            setPosition({ x: 0, y: 0 });
        }
    }, [questionInfo?.actualQuestion]);

    const enableFullscreen = async () => {
        const element = document.documentElement;
        try {
            await dispatch(
                switchTab({
                    interviewId: form?.interviewId,
                    message: 'Fullscreen Mode Enabled',
                    callback: () => {},
                })
            );
            if (element.requestFullscreen) {
                element.requestFullscreen();
                logSuccess('Fullscreen enabled using standard API');
            } else if (element.mozRequestFullScreen) {
                element.mozRequestFullScreen();
                logSuccess('Fullscreen enabled using Firefox API');
            } else if (element.webkitRequestFullscreen) {
                element.webkitRequestFullscreen();
                logSuccess('Fullscreen enabled using Safari API');
            } else if (element.msRequestFullscreen) {
                element.msRequestFullscreen();
                logSuccess('Fullscreen enabled using IE/Edge API');
            } else {
                logError('Fullscreen API not supported on this browser', 'EnableFullscreen function failed');
            }
        } catch (error) {
            logError('Error enabling fullscreen mode', error);
        }
    };

    const handleFullscreenChange = async () => {
        try {
            if (!document.fullscreenElement && !document.webkitFullscreenElement) {
                setShowModal(true);
                await dispatch(
                    switchTab({
                        interviewId: form?.interviewId,
                        message: 'Fullscreen Mode Disabled',
                        callback: () => {},
                    })
                );
                logError('Fullscreen mode exited', 'Displaying fullscreen modal');
            } else {
                logSuccess('Fullscreen mode is active');
            }
        } catch (error) {
            logError('Error handling fullscreen change', error);
        }
    };

    useEffect(() => {
        if (showModal) {
            logSuccess('Fullscreen modal activated');
            const timer = setInterval(() => {
                setCountdown((prev) => {
                    if (prev === 1) {
                        clearInterval(timer);
                        logError('Countdown finished', 'Ending call due to fullscreen exit');
                        dispatch(
                            switchTab({
                                interviewId: form?.interviewId,
                                message: 'Fullscreen Mode Exited',
                                callback: () => handleEndCall(false, 'Fullscreen exit modal timer end'),
                            })
                        );
                        return 0;
                    }
                    return prev - 1;
                });
            }, 1000);

            return () => {
                clearInterval(timer);
                logSuccess('Fullscreen modal timer cleared');
            };
        }
    }, [showModal]);

    useEffect(() => {
        try {
            enableFullscreen();
            document.addEventListener('fullscreenchange', handleFullscreenChange);
            document.addEventListener('webkitfullscreenchange', handleFullscreenChange);
            logSuccess('Fullscreen change listeners added');
            return () => {
                document.removeEventListener('fullscreenchange', handleFullscreenChange);
                document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
                logSuccess('Fullscreen change listeners removed');
            };
        } catch (error) {
            logError('Error setting up fullscreen listeners', error);
        }
    }, []);

    const FullscreenContent = () => (
        <div className="d-flex flex-column gap-10 justify-content-center align-items-center">
            <div className="countdown-text">{countdown}</div>
            <div className="d-flex flex-column justify-content-center align-items-center">
                <div className="countdown-body">
                    To continue with this interview, please turn on fullscreen mode within the next 30 seconds.
                </div>
                <div className="countdown-body">
                    The interview will end automatically if fullscreen mode is not enabled.
                </div>
            </div>
        </div>
    );

    const toggleFullScreen = () => {
        try {
            setShowModal(false);
            setCountdown(30);
            enableFullscreen();
            logSuccess('Re-entering fullscreen mode');
        } catch (error) {
            logError('Error toggling fullscreen mode', error);
        }
    };

    const timeoutRef = useRef(null);

    const resetTimeout = () => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
            if (!isPlaying && !stopForProceed) {
                setShowStuckPopup(true);
            }
        }, 15000); // 15 seconds
    };

    useEffect(() => {
        if (isPlaying || stopForProceed) {
            setShowStuckPopup(false); // Hide popup if isPlaying becomes true
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        } else {
            resetTimeout();
        }

        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, [isPlaying, stopForProceed]);

    useEffect(() => {
        resetTimeout();
    }, [prepareTime, answerTime]);

    return timer > 0 ? (
        <DesktopProgressPage timer={timer} />
    ) : !isUpdated && !recordingState?.browser?.running ? (
        <FullPageProgress />
    ) : isNetworkError ? (
        <NetworkConnectionLost inInterviewRoom />
    ) : (
        <>
            {isMobileOrTabletDevice() ? (
                <MobileMainView
                    form={form}
                    roomId={roomId}
                    toggleControls={toggleControls}
                    questionInfo={questionInfo}
                    isPlaying={isPlaying}
                    prepareTime={prepareTime}
                    setPrepareTime={setPrepareTime}
                    answerTime={answerTime}
                    setAnswerTime={setAnswerTime}
                    socket={socket}
                    handleEndCall={handleEndCall}
                    recording={recording}
                    interviewStarted={interviewStarted}
                    onlineStatus={onlineStatus}
                    setAnswerDuration={setAnswerDuration}
                    answerDuration={answerDuration}
                    handleSubmit={handleSubmit}
                    candidatePeer={candidatePeer}
                    displayInput={displayInput}
                    popupInputs={popupInputs}
                    setPopupInputs={setPopupInputs}
                    startRecording={startRecording}
                    playAudio={playAudio}
                    skipTimer={skipTimer}
                    waitingPrepTime={waitingPrepTime}
                    setDisplayInput={setDisplayInput}
                    updateSocket={updateSocket}
                    conversationData={conversationData}
                    handleConSubmit={handleConSubmit}
                    speakText={speakText}
                />
            ) : (
                <>
                    <Modal
                        type={1}
                        isOpen={showModal}
                        headerText="🔒 Stay in Fullscreen Mode"
                        buttonText="Re-enter Fullscreen"
                        handleClick={toggleFullScreen}
                        buttonStyle="modal-btn"
                        primaryBtnText="End call"
                        primaryBtnStyle="primary-modal-btn"
                        handlePrimaryBtnClick={() => {
                            dispatch(
                                switchTab({
                                    interviewId: form?.interviewId,
                                    message: 'Fullscreen Mode Exited',
                                    callback: () => handleEndCall(false, 'Fullscreen exit call end'),
                                })
                            );
                        }}
                        Content={FullscreenContent}
                        className="timer-count-body"
                        buttonsDivClass="countdown-buttons"
                        headerLine
                    />
                    <Viewer setScreenShareVidRef={setScreenShareVidRef} />
                    <ConferenceMainView
                        form={form}
                        toggleControls={toggleControls}
                        questionInfo={questionInfo}
                        isPlaying={isPlaying}
                        prepareTime={prepareTime}
                        setPrepareTime={setPrepareTime}
                        answerTime={answerTime}
                        setAnswerTime={setAnswerTime}
                        handleEndCall={handleEndCall}
                        recording={recording}
                        interviewStarted={interviewStarted}
                        onlineStatus={onlineStatus}
                        setAnswerDuration={setAnswerDuration}
                        answerDuration={answerDuration}
                        handleSubmit={handleSubmit}
                        candidatePeer={candidatePeer}
                        displayInput={displayInput}
                        setDisplayInput={setDisplayInput}
                        isUpdated={isUpdated}
                        playAudio={playAudio}
                        skipTimer={skipTimer}
                        waitingPrepTime={waitingPrepTime}
                        waitingAnswerTime={waitingAnswerTime}
                        setWaitingAnswerTime={setWaitingAnswerTime}
                        startRecording={startRecording}
                        timerEnd={timerEnd}
                        updateSocket={updateSocket}
                        setCodeEditorForm={setCodeEditorForm}
                        codeEditorForm={codeEditorForm}
                        hasAnythingChangedInCodeEditor={hasAnythingChangedInCodeEditor}
                        setHasAnythingChangedInCodeEditor={setHasAnythingChangedInCodeEditor}
                        isQuestionTypeCoding={isQuestionTypeCoding}
                        setIsSubmitModalOpen={setIsSubmitModalOpen}
                        isSubmitModalOpen={isSubmitModalOpen}
                        position={position}
                        setPosition={setPosition}
                        isQuestionTypeMcq={isQuestionTypeMcq}
                        mcqForm={mcqForm}
                        questionNumberTotal={questionNumberTotal}
                        selectedOption={selectedOption}
                        setSelectedOption={setSelectedOption}
                        saveMcqQuestion={saveMcqQuestion}
                        aptitude={aptitude}
                        stopForProceed={stopForProceed}
                        setStopForProceed={setStopForProceed}
                        conversationData={conversationData}
                        handleConSubmit={handleConSubmit}
                        speakText={speakText}
                        evaluateNextQuestionRef={evaluateNextQuestionRef}
                        setTranscript={setTranscript}
                        showStuckPopup={showStuckPopup}
                        setShowStuckPopup={setShowStuckPopup}
                        mcqInterruptedTime={mcqInterruptedTime}
                    />
                </>
            )}
            <VideoStream
                interviewId={form?.interviewId}
                roomId={roomId}
                eyeMovement={eyeMovement}
                faceCount={faceCount}
                deviceCount={deviceCount}
                setEyeMovement={setEyeMovement}
                setFaceCount={setFaceCount}
                setDeviceCount={setDeviceCount}
                saveMovement={saveMovement}
                lookingTimer={isQuestionTypeCoding ? 90 : 15}
            />
            <TabSwitch interviewId={form?.interviewId} />
        </>
    );
};
